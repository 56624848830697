import { LocationParams } from '../../utils/location'
import { track } from '../../utils/track'

export const aflGaClientIdKey = 'afl_ga_client_id'
export const aflGaSessionIdKey = 'afl_ga_session_id'

type AflTrackingParams = {
  clientId: string
  sessionId: string
}

const setAflTrackingSessionStorage = ({
  clientId,
  sessionId,
}: AflTrackingParams) => {
  sessionStorage.setItem(aflGaClientIdKey, clientId)
  sessionStorage.setItem(aflGaSessionIdKey, sessionId)
}

const trackAflTracking = ({ clientId, sessionId }: AflTrackingParams) => {
  track({
    event: 'crossSiteTracking',
    afl_ga_client_id: clientId,
    afl_ga_session_id: sessionId,
  })
}

export const aflTracking = (params: LocationParams) => {
  //  Check if the client and session ids are available in the URL query parameters
  const clientId = params[aflGaClientIdKey]
  const sessionId = params[aflGaSessionIdKey]

  if (clientId && sessionId) {
    // If so, push these to the data layer and set in session storage
    trackAflTracking({ clientId, sessionId })
    setAflTrackingSessionStorage({ clientId, sessionId })
  } else {
    // If not, attempt to retrieve them from session storage
    const storedClientId = sessionStorage.getItem(aflGaClientIdKey)
    const storedSessionId = sessionStorage.getItem(aflGaSessionIdKey)
    if (storedClientId && storedSessionId) {
      // If they are in session storage, push them to data layer
      trackAflTracking({ clientId: storedClientId, sessionId: storedSessionId })
    }
  }
}
