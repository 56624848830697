import { Text } from '@gameonsports/components/cjs/TextV3'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  text-align: center;
  padding: 5rem 3.75rem;
`

interface RegistrationUnavailableProps {
  associationName: string
  competitionName: string
  seasonName: string
  teamName?: string
}

const RegistrationUnavailable: React.FC<RegistrationUnavailableProps> = ({
  associationName,
  competitionName,
  seasonName,
  teamName,
}) => (
  <Container>
    <Text weight="700" size="22">
      Registration form unavailable.
    </Text>
    <Text>
      It looks like you’ve tried to access the registration form for{' '}
      <Text weight="700">
        {teamName && `${teamName} - `}
        {associationName} - {competitionName}, {seasonName}
      </Text>{' '}
      season which is not currently open. Please check with {associationName}{' '}
      for more information.
    </Text>
  </Container>
)

export default RegistrationUnavailable
