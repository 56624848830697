import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { REGISTRATION_SESSION_KEY } from '../../constants/registrationSession'
import {
  DiscoverTeam,
  Gender,
  SeasonRegistrationUserType,
} from '../../generated/graphql'
import { isSSR } from '../../utils/ssr'

interface ObjectStringMap {
  [key: string]: string | undefined
}

export interface Registration {
  who: string | null
  registrationType: SeasonRegistrationUserType | null
  gender: Gender | null
  dateOfBirth: string | null
  name: string | null
  marketingOptIn: boolean | null
  profileId: string | null
  participantId: string | null
  hasDuplicateProfiles: boolean | null
  hasExternalIdStep: boolean | null
  hasExternalIdLinked: boolean | null
  hasProducts: boolean | null
  membershipFeePaymentIds: string[]
  selectedAdvancedFeeIds: string[]
  team: DiscoverTeam | null
  gradeId: string | null
  customFields: ObjectStringMap
  setDateOfBirth: Dispatch<SetStateAction<string | null>>
  setGender: Dispatch<SetStateAction<Gender | null>>
  setName: Dispatch<SetStateAction<string | null>>
  setWho: Dispatch<SetStateAction<string | null>>
  setMarketingOptIn: Dispatch<SetStateAction<boolean | null>>
  setProfileId: Dispatch<SetStateAction<string | null>>
  setParticipantId: Dispatch<SetStateAction<string | null>>
  setHasDuplicateProfiles: Dispatch<SetStateAction<boolean | null>>
  setHasExternalIdStep: Dispatch<SetStateAction<boolean | null>>
  setHasExternalIdLinked: Dispatch<SetStateAction<boolean | null>>
  setMembershipFeePaymentIds: Dispatch<SetStateAction<string[]>>
  setSelectedAdvancedFeeIds: Dispatch<SetStateAction<string[]>>
  setTeam: Dispatch<SetStateAction<DiscoverTeam | null>>
  setGradeId: Dispatch<SetStateAction<string | null>>
  setCustomFields: Dispatch<SetStateAction<ObjectStringMap>>

  deferredFees: boolean
  setDeferredFees: Dispatch<SetStateAction<boolean>>
  isDeferredFeesEnabled: boolean
  deferredFeesDueDate: string | null
}

const storageString =
  !isSSR && window.sessionStorage.getItem(REGISTRATION_SESSION_KEY)
const storageValues = storageString && JSON.parse(storageString)

const initialValues = {
  whoValue: null,
  registrationTypeValue: null,
  genderValue: null,
  dateOfBirthValue: null,
  nameValue: null,
  marketingOptInValue: null,
  profileIdValue: null,
  participantIdValue: null,
  hasDuplicateProfiles: null,
  hasExternalIdStepValue: null,
  hasExternalIdLinkedValue: null,
  hasProductsValue: null,
  membershipFeePaymentIdsValue: [],
  selectedAdvancedFeeIdsValue: [],
  team: null,
  gradeId: null,
  customFields: {},
  ...storageValues,
}

export const RegContext = React.createContext<Registration>({
  who: null,
  registrationType: null,
  gender: null,
  dateOfBirth: null,
  name: null,
  marketingOptIn: null,
  profileId: null,
  participantId: null,
  hasDuplicateProfiles: null,
  hasExternalIdStep: null,
  hasExternalIdLinked: null,
  hasProducts: null,
  membershipFeePaymentIds: [],
  selectedAdvancedFeeIds: [],
  team: null,
  gradeId: null,
  customFields: {},
  deferredFees: false,
  deferredFeesDueDate: null,
  setDeferredFees: () => null,
  isDeferredFeesEnabled: false,
  setWho: () => null,
  setGender: () => null,
  setDateOfBirth: () => null,
  setName: () => null,
  setMarketingOptIn: () => null,
  setProfileId: () => null,
  setParticipantId: () => null,
  setHasDuplicateProfiles: () => null,
  setHasExternalIdStep: () => null,
  setHasExternalIdLinked: () => null,
  setMembershipFeePaymentIds: () => null,
  setSelectedAdvancedFeeIds: () => null,
  setTeam: () => null,
  setGradeId: () => null,
  setCustomFields: () => {},
})

export const Provider: React.FC<Partial<Registration>> = ({
  children,
  who,
  registrationType,
  gender,
  dateOfBirth,
  name,
  marketingOptIn,
  profileId,
  participantId,
  hasDuplicateProfiles,
  hasExternalIdStep,
  hasExternalIdLinked,
  hasProducts,
  membershipFeePaymentIds,
  selectedAdvancedFeeIds,
  team,
  gradeId,
  customFields,
  isDeferredFeesEnabled,
  deferredFeesDueDate,
}) => {
  const [whoValue, setWho] = useState<string | null>(
    who || initialValues.whoValue,
  )
  const [registrationTypeValue, setType] =
    useState<SeasonRegistrationUserType | null>(
      registrationType || initialValues.registrationTypeValue,
    )
  const [genderValue, setGender] = useState<Gender | null>(
    gender || initialValues.genderValue,
  )
  const [dateOfBirthValue, setDateOfBirth] = useState<string | null>(
    dateOfBirth || initialValues.dateOfBirthValue,
  )
  const [nameValue, setName] = useState<string | null>(
    name || initialValues.nameValue,
  )

  const [marketingOptInValue, setMarketingOptIn] = useState<boolean | null>(
    marketingOptIn || initialValues.marketingOptInValue,
  )

  const [profileIdValue, setProfileId] = useState<string | null>(
    profileId || initialValues.profileIdValue,
  )

  const [participantIdValue, setParticipantId] = useState<string | null>(
    participantId || initialValues.participantIdValue,
  )

  const [hasDuplicateProfilesValue, setHasDuplicateProfiles] = useState<
    boolean | null
  >(hasDuplicateProfiles || initialValues.hasDuplicateProfiles)

  const [hasExternalIdLinkedValue, setHasExternalIdLinked] = useState<
    boolean | null
  >(hasExternalIdLinked || initialValues.hasExternalIdLinkedValue)

  // The linking step will only be set to show as *linked* after completing the linking
  // in the reg flow, otherwise it is set to null. Doing this will return `hasExternalIdStepValue`
  // as `true` from the BE, which would make the step immediately disappear. This logic only
  // uses this override if *linked* is nullish, so it will continue to display the step (when it
  // is linked) for the rest of that registration. When the next registration is started,
  // *linked* will return as `null`, so the step will NOT be shown.
  const [hasExternalIdStepValue, setHasExternalIdStep] = useState<
    boolean | null
  >(
    !hasExternalIdLinkedValue
      ? hasExternalIdStep
      : initialValues.hasExternalIdStepValue,
  )

  const [membershipFeePaymentIdsValue, setMembershipFeePaymentIds] = useState<
    string[]
  >(membershipFeePaymentIds || initialValues.membershipFeePaymentIdsValue)

  const [selectedAdvancedFeeIdsValue, setSelectedAdvancedFeeIds] = useState<
    string[]
  >(selectedAdvancedFeeIds || initialValues.selectedAdvancedFeeIdsValue)

  const [hasProductsValue, setHasProducts] = useState<boolean | null>(
    hasProducts || initialValues.hasProductsValue,
  )

  const [teamValue, setTeam] = useState<DiscoverTeam | null>(
    team || initialValues.team,
  )

  const [gradeIdValue, setGradeId] = useState<string | null>(
    gradeId || initialValues.gradeId,
  )

  const [customFieldsValue, setCustomFields] = useState<ObjectStringMap>(
    customFields || initialValues.customFields,
  )

  const [deferredFees, setDeferredFees] = useState(initialValues.deferredFees)

  useEffect(() => {
    if (!!who) {
      setWho(who)
    }
    if (!!registrationType) {
      setType(registrationType)
    }
    if (!!gender) {
      setGender(gender)
    }
    if (!!dateOfBirth) {
      setDateOfBirth(dateOfBirth)
    }
    if (!!name) {
      setName(name)
    }
    if (!!marketingOptIn) {
      setMarketingOptIn(marketingOptIn)
    }
    if (!!profileId) {
      setProfileId(profileId)
    }
    if (!!participantId) {
      setParticipantId(participantId)
    }
    if (!!hasDuplicateProfiles) {
      setHasDuplicateProfiles(hasDuplicateProfiles)
    }
    if (!!hasExternalIdStep) {
      setHasExternalIdStep(hasExternalIdStep)
    }
    if (!!hasExternalIdLinked) {
      setHasExternalIdLinked(hasExternalIdLinked)
    }
    if (!!hasProducts) {
      setHasProducts(hasProducts)
    }
    if (!!membershipFeePaymentIds) {
      setMembershipFeePaymentIds(membershipFeePaymentIds)
    }
    if (!!selectedAdvancedFeeIds) {
      setSelectedAdvancedFeeIds(selectedAdvancedFeeIds)
    }
    if (!!team) {
      setTeam(team)
    }
    if (!!gradeId) {
      setGradeId(gradeId)
    }
    if (!!customFields) {
      setCustomFields(customFields)
    }
  }, [
    who,
    registrationType,
    gender,
    dateOfBirth,
    name,
    marketingOptIn,
    profileId,
    participantId,
    hasDuplicateProfiles,
    hasExternalIdStep,
    hasExternalIdLinked,
    hasProducts,
    membershipFeePaymentIds,
    selectedAdvancedFeeIds,
    team,
    gradeId,
    customFields,
  ])

  useEffect(() => {
    if (window.sessionStorage) {
      const registrationContext = {
        whoValue,
        registrationTypeValue,
        genderValue,
        dateOfBirthValue,
        nameValue,
        marketingOptInValue,
        profileIdValue,
        participantIdValue,
        hasDuplicateProfilesValue,
        hasExternalIdStepValue,
        hasExternalIdLinkedValue,
        hasProductsValue,
        membershipFeePaymentIdsValue,
        selectedAdvancedFeeIdsValue,
        teamValue,
        gradeIdValue,
        customFieldsValue,
      }
      window.sessionStorage.setItem(
        REGISTRATION_SESSION_KEY,
        JSON.stringify(registrationContext),
      )
    }
  }, [
    whoValue,
    registrationTypeValue,
    genderValue,
    dateOfBirthValue,
    nameValue,
    marketingOptInValue,
    profileIdValue,
    participantIdValue,
    hasDuplicateProfilesValue,
    hasExternalIdStepValue,
    hasExternalIdLinkedValue,
    hasProductsValue,
    membershipFeePaymentIdsValue,
    selectedAdvancedFeeIdsValue,
    teamValue,
    gradeIdValue,
    customFieldsValue,
  ])

  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem(REGISTRATION_SESSION_KEY)
    }
  }, [])

  return (
    <RegContext.Provider
      value={{
        who: whoValue,
        registrationType: registrationTypeValue,
        gender: genderValue,
        dateOfBirth: dateOfBirthValue,
        name: nameValue,
        marketingOptIn: marketingOptInValue,
        profileId: profileIdValue,
        participantId: participantIdValue,
        hasDuplicateProfiles: hasDuplicateProfilesValue,
        hasExternalIdStep: hasExternalIdStepValue,
        hasExternalIdLinked: hasExternalIdLinkedValue,
        hasProducts: hasProductsValue,
        membershipFeePaymentIds: membershipFeePaymentIdsValue,
        selectedAdvancedFeeIds: selectedAdvancedFeeIdsValue,
        team: teamValue,
        gradeId: gradeIdValue,
        customFields: customFieldsValue,
        setDateOfBirth,
        setGender,
        setName,
        setWho,
        setMarketingOptIn,
        setProfileId,
        setParticipantId,
        setHasDuplicateProfiles,
        setHasExternalIdStep,
        setHasExternalIdLinked,
        setMembershipFeePaymentIds,
        setSelectedAdvancedFeeIds,
        setTeam,
        setGradeId,
        setCustomFields,

        deferredFees,
        setDeferredFees,
        isDeferredFeesEnabled: isDeferredFeesEnabled ?? false,
        deferredFeesDueDate: deferredFeesDueDate ?? null,
      }}
    >
      {children}
    </RegContext.Provider>
  )
}

export const Consumer = RegContext.Consumer

export default RegContext
